import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Featuresitems from "../components/Features/Featuresitems";
import FooterData from "../components/Footer/FooterData";
import PrototypeFooter from "../components/Footer/PrototypeFooter";

const Process = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Our Process"
        Pdescription="Qualify your leads & recognize the value of word your customer will love you"
      />
      <section className="process_area bg_color sec_pad">
        <div className="container">
          <div className="features_info">
            {/* <img
              className="dot_img"
              src={require("../img/home4/divider.png")}
              alt=""
            /> */}
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="process_1.png"
              iImg="icon01.png"
              ftitle="Web & Mobile Development"
              descriptions="Trillion Thoughts implies designing, building, integrating, scaling and maintaining dynamic software that work via internet and we have specialized team for our client to feel splendid on every services."
            />
            <Featuresitems
              rowClass="row"
              aClass="pl_100"
              fimage="process_2.png"
              iImg="icon02.png"
              ftitle="Cloud Hosting Services"
              descriptions="Trillion Thoughts is responsible for provisioning and managing the hardware and software associated with the service being offered. Cloud hosting helps to speedify server setup process, high uptime and availability, faster website speed and performance."
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="process_3.png"
              iImg="icon3.png"
              ftitle="Graphic Designing"
              descriptions="We stay focused on creating an outstanding design service that makes your business high. Check out our work samples to see what we can do for you!"
            />
            <Featuresitems
              rowClass="row"
              aClass="pl_100"
              fimage="process_4.png"
              iImg="icon_04.png"
              ftitle="We always try to be the best support to you as possible"
              descriptions="Qualify your leads & recognize the value of word your customer will love you"
            />

            {/* <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div> */}
          </div>
        </div>
      </section>
      {/* <FooterTwo FooterData={FooterData}/> */}
      <PrototypeFooter
        rclass={"payment_footer_area_two"}
        FooterData={FooterData}
      />
    </div>
  );
};
export default Process;
