import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Teams from "../components/Team/Team";
import FooterData from "../components/Footer/FooterData";
import PrototypeFooter from "../components/Footer/PrototypeFooter";

const Team = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-05.jpg"
        Ptitle="Our Team"
        Pdescription="Effective teams pursue a common outcome and have a common goal they want to reach."
      />
      <Teams />
      {/* <FooterTwo FooterData={FooterData} /> */}
      <PrototypeFooter
        rclass={"payment_footer_area_two"}
        FooterData={FooterData}
      />
    </div>
  );
};
export default Team;
