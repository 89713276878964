import React, { Component } from "react";
import Slider from "react-slick";
class AgencyAbout extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <section className="agency_about_area d-flex bg_color">
        <div className="col-lg-6 about_content_left ">
          <div className="about_content mb_30">
            <h2 className="f_size_30 f_700 l_height45 mb_20">
              We craft marketing & digital products that grow businesses.
              <br />
              <p style={{ fontStyle: "italic", fontWeight: "lighter" }}>
                {" "}
                Our main objective is to provide complete client satisfaction
                through exquisice work quality of our development team. We offer
                a lot of customization options and scalability to develop
                future-ready business apps. We integrate technological
                advancements to build innovative applications in such a
                costefficient way.
              </p>
            </h2>
            <a href="/Team" className="about_btn">
              VIEW OUR TEAM
            </a>
          </div>
        </div>
        <div className="col-lg-6 about_img">
          <a href=".#" className="pluse_icon">
            <i className="ti-plus"></i>
          </a>
          <Slider className="about_img_slider" {...settings}>
            <div className="item">
              <div className="about_item w45">
                <img src={require("../../img/teamwork1.JPG")} alt="" />
                <div className="about_text">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {`"Great things are rarely achieved by just one person. Usually, they are accomplished by a group of people.."`}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/teamwork2.JPG")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {`"We deliver Quality products & services"`}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/teamwork3.JPG")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {` “Individual commitment to a group effort—that is what makes a team work, a company work, a society work, a civilisation work.”`}
                  </h5>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default AgencyAbout;
