import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
const Team = () => {
  return (
    <section className="experts_team_area sec_pad">
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <Sectitle
              sClass="sec_title text-center mb_70"
              Title={`TEAM BIOMET`}
              tClass="t_color3"
            />
          </div>
          {/* <div className="row">
            <div style={{ display: "flex", gap: "2rem" }}>
              {" "}
              <div className="col-lg-3 col-sm-6">
                <Teamitem
                  teamImage="team_01.jpg"
                  memberN="Shailaja Balaji"
                  memberd="Chief Executive Officer"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <Teamitem
                  teamImage="team_01.jpg"
                  memberN="Balaji LakshmiNarayanan"
                  memberd="Chief Technology Officer"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <Teamitem
                  teamImage="team_01.jpg"
                  memberN="Pavithra Jeyasingh"
                  memberd="Chief Operating Officer"
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <Teamitem
                  teamImage="team_01.jpg"
                  memberN="Gajalakshmi Varadharajan"
                  memberd="Senior Manager"
                />
              </div>
            </div>
          </div> */}
        </div>
        <div
          className="row"
          style={{ marginLeft: "10rem", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "2rem", marginLeft: "-4rem" }}>
            <Sectitle
              sClass="sec_title text-center mb_70"
              Title="Our Development Experts"
              tClass="t_color3"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="Santhosh.png"
              memberN="Santhosh LakshmiNarayanan"
              memberd="Associate Director"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="Suhel.png"
              memberN="Suhel Chan Basha"
              memberd="Associate Director"
            />
          </div>
        </div>
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title="Our Development Team"
          tClass="t_color3"
        />
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="Ragul.png"
              memberN="Rahul Palanivel"
              memberd="UI Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="lokesh.jpg"
              memberN="Lokesh Kumar Viswanathan"
              memberd="UI Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="sathish.jpg"
              memberN="Sathish Mohan"
              memberd="UI Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="balajiMohan.png"
              memberN="Balaji Mohan"
              memberd="Backend Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="Savi2.jpg"
              memberN="Savithri Murali"
              memberd="Backend Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="Kamalesh.jpg"
              memberN="Kamalesh Devaraj"
              memberd="UI Developer"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="pradeep.png"
              memberN="Pradeep Sigamani"
              memberd="Backend Developer"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};
export default Team;
