import React, { Component } from "react";
import Reveal from "react-reveal";
class StartupFeatures extends Component {
  render() {
    return (
      <section className="startup_fuatures_area sec_pad">
        <div className="container">
          <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
            <Reveal effect="fadeInLeft">
              <h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">
                Services
                <br />
              </h2>
            </Reveal>
          </div>
          <ul className="nav nav-tabs startup_tab" id="myTab" role="tablist">
            <Reveal effect="fadeInLeft">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="market-tab"
                  data-toggle="tab"
                  href="#market"
                  role="tab"
                  aria-controls="market"
                  aria-selected="true"
                >
                  <span className="icon">
                    <i className="icon-screen-tablet"></i>
                  </span>
                  <h3>
                    Device
                    <br /> Management
                  </h3>
                </a>
              </li>
            </Reveal>
            <Reveal effect="fadeInLeft" duration={1400}>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="app-tab"
                  data-toggle="tab"
                  href="#app"
                  role="tab"
                  aria-controls="app"
                  aria-selected="false"
                >
                  <span className="icon">
                    <i class="fas fa-key"></i>
                  </span>
                  <h3>
                    Access
                    <br /> Management
                  </h3>
                </a>
              </li>
            </Reveal>
            <Reveal effect="fadeInLeft" duration={2000}>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="hubstaff-tab"
                  data-toggle="tab"
                  href="#hubstaff"
                  role="tab"
                  aria-controls="hubstaff"
                  aria-selected="false"
                >
                  <span className="icon">
                    <i class="icon-settings"></i>
                  </span>
                  <h3>
                    Configuration
                    <br /> Service
                  </h3>
                </a>
              </li>
            </Reveal>
            <Reveal effect="fadeInLeft" duration={2400}>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="engine-tab"
                  data-toggle="tab"
                  href="#engine"
                  role="tab"
                  aria-controls="engine"
                  aria-selected="false"
                >
                  <span className="icon">
                    <i className="icon-refresh"></i>
                  </span>
                  <h3>
                    Reports
                    <br /> Traction
                  </h3>
                </a>
              </li>
            </Reveal>
            <Reveal effect="fadeInLeft" duration={2800}>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="business-tab"
                  data-toggle="tab"
                  href="#business"
                  role="tab"
                  aria-controls="business"
                  aria-selected="false"
                >
                  <span className="icon">
                    <i className="icon-bulb"></i>
                  </span>
                  <h3>
                    Approvals
                    <br />
                  </h3>
                </a>
              </li>
            </Reveal>
          </ul>
          <div className="tab-content startup_tab_content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="market"
              role="tabpanel"
              aria-labelledby="market-tab"
            >
              <div className="startup_tab_img">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15rem",
                  }}
                >
                  <div>
                    <div className="web_img">
                      <img
                        src={require("../../img/deviceManagement.png")}
                        alt=""
                        style={{
                          width: "900px",
                          filter: "drop-shadow(15px 15px 4px lightgray)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="startup_tab_img">
                    <div className="phone_img">
                      <div className="frame">
                        <div>
                          <p className="feature-info">
                            &ldquo;Device Management System &rdquo;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="app"
              role="tabpanel"
              aria-labelledby="app-tab"
            >
              <div className="startup_tab_img">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15rem",
                  }}
                >
                  <div className="web_img">
                    <img
                      src={require("../../img/accessManagement.png")}
                      alt=""
                      style={{
                        width: "900px",
                        filter: "drop-shadow(15px 15px 4px lightgray)",
                      }}
                    />
                  </div>
                  <div className="startup_tab_img">
                    <div className="phone_img">
                      <div className="frame">
                        <div>
                          <p className="feature-info">
                            &ldquo;Access Management System &rdquo;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="hubstaff"
              role="tabpanel"
              aria-labelledby="hubstaff-tab"
            >
              <div className="startup_tab_img">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15rem",
                  }}
                >
                  <div className="web_img">
                    <img
                      src={require("../../img/configurationService.png")}
                      alt=""
                      style={{
                        width: "900px",
                        filter: "drop-shadow(15px 15px 4px lightgray)",
                      }}
                    />
                  </div>
                  <div className="startup_tab_img">
                    <div className="phone_img">
                      <div className="frame">
                        <div>
                          <p className="feature-info">
                            &ldquo;Configuration Service &rdquo;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="engine"
              role="tabpanel"
              aria-labelledby="engine-tab"
            >
              <div className="startup_tab_img">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15rem",
                  }}
                >
                  <div className="web_img">
                    <img
                      src={require("../../img/Reports.png")}
                      alt=""
                      style={{
                        width: "900px",
                        filter: "drop-shadow(15px 15px 4px lightgray)",
                      }}
                    />
                  </div>
                  <div className="startup_tab_img">
                    <div className="phone_img">
                      <div className="frame">
                        <div>
                          <p className="feature-info">
                            &ldquo;Reports Track &rdquo;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="business"
              role="tabpanel"
              aria-labelledby="business-tab"
            >
              <div className="startup_tab_img">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15rem",
                  }}
                >
                  <div className="web_img">
                    <img
                      src={require("../../img/Approvals.png")}
                      alt=""
                      style={{
                        width: "900px",
                        filter: "drop-shadow(15px 15px 4px lightgray)",
                      }}
                    />
                  </div>
                  <div className="startup_tab_img">
                    <div className="phone_img">
                      <div className="frame">
                        <div>
                          <p className="feature-info">
                            &ldquo;Approval Management &rdquo;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default StartupFeatures;
