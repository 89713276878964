import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import AgencyAbout from "../components/About/AgencyAbout";
import Partner from "../components/Partner";
import ServiceData from "../components/Service/ServiceData";
import FooterData from "../components/Footer/FooterData";
import PrototypeFooter from "../components/Footer/PrototypeFooter";

const About = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="About Us"
        Pdescription="We always try to be the best support to you as possible!"
      />
      {/* <Sservice /> */}
      <AgencyAbout ServiceData={ServiceData} />
      <Partner pClass="partner_logo_area_five bg_color" />
      {/* <FooterTwo FooterData={FooterData}/> */}
      <PrototypeFooter
        rclass={"payment_footer_area_two"}
        FooterData={FooterData}
      />
    </div>
  );
};
export default About;
